
// Seagull print css
// --------------------------------------------------

.region--header,
.region--footer,
.panel-sidebar-left-stacked .sidebar,
.panel-sidebar-right-stacked .sidebar,
.status-messages,
ul.tabs.primary,
.field--addthis,
.field--print-button,
.field--bookmarks {
  display: none;
}